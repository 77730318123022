<template>
    <div>
        <MapDrivers></MapDrivers>
    </div>
</template>
<script>

import MapDrivers from "@core/components/maps/MapDrivers.vue";

export default {
    components: {
        MapDrivers,
    },
    data() {
        return {
        }
    },
    methods: {

    },
    mounted() {
    },

};
</script>
